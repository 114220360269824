@tailwind base;
@tailwind components;
@tailwind utilities;
@import '~flowbite';

.text-anywhere {
    overflow-wrap: anywhere;
}

.flowbiteInput {
    @apply py-2 !important;
}

pre {
    @apply font-sans !important;
}


@layer base {
    :root {
        --radius: 0.5rem
    }
}